import {
  Elements,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import type { PaymentRequest } from "@stripe/stripe-js";
import { useEffect, useRef, useState } from "react";
import { createOrder, createPaymentIntent, getPaymentIntent } from "../../apis/payment";
import { Box } from "@chakra-ui/react";
import { useCurrentCountryCode } from "../../hooks";
import { CheckoutPayload } from "../../types/type";
import { useLocation } from "react-router-dom";
import { PAYMENT_PROCESSORS, ROUTES } from "../../constants";
import { getCountryCodeFromIP, getCountryFromIP, getIPAddress, getOSInfo } from "../../utils/utils";
import { useFallbackGooglePayStore } from "../../libs/store";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

type ApplePayButtonProps = {
  amount: number;
  handleCheckout: (payload: CheckoutPayload) => Promise<void>;
  setPaymentError: React.Dispatch<React.SetStateAction<string>>;
};

type CheckoutApplePayProps = {
  amount: number;
  handleCheckout: (payload: CheckoutPayload) => Promise<void>;
  setPaymentError: React.Dispatch<React.SetStateAction<string>>;
};

const ApplePayButton = ({
  amount,
  handleCheckout,
  setPaymentError,
}: ApplePayButtonProps) => {
  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <CheckoutApplePay
            amount={amount}
            handleCheckout={handleCheckout}
            setPaymentError={setPaymentError}
          />
        </Elements>
      )}
    </>
  );
};

const CheckoutApplePay = ({
  amount,
  handleCheckout,
  setPaymentError,
}: CheckoutApplePayProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  );

  const { pathname } = useLocation();
  const countryCode = useCurrentCountryCode();

  const amountRef = useRef<number>(amount);
  const setFallbackGooglePay = useFallbackGooglePayStore(
    (state) => state.setFallbackGooglePay,
  );

  // Init Stripe PaymentRequest
  useEffect(() => {
    if (stripe) {
      initStripePaymentRequest(stripe);
    }
  }, [stripe]);

  const getLabel = (amount: number) => {
    if (
      (amount === 27 || amount === 95) &&
      pathname.includes(ROUTES.MEMBERSHIP)
    )
      return "Viral Profits Endless Video Ideas System";

    switch (amount) {
      case 30:
      case 34:
      case 122:
        return `Viral Profits 5-Day Fast Start + Endless Video Ideas System`;
      case 97:
      case 197:
      case 297:
        return "Viral Profits 7 Figure Blueprint";
      default:
        // 3 & 7
        return `Viral Profits 5-Day Fast Start`;
    }
  };

  useEffect(() => {
    paymentRequest?.update({
      total: {
        label: getLabel(Number(amount)),
        amount: Number(amount) * 100,
      },
    });

    amountRef.current = amount;
  }, [amount]);

  const initStripePaymentRequest = (stripe: Stripe) => {
    const paymentRequest = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: getLabel(Number(amount)),
        amount: Number(amount) * 100,
      },
      disableWallets: ["link", "googlePay"],

      // https://docs.stripe.com/js/payment_request/create
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
    });

    // Check the availability of the Payment Request API.
    paymentRequest.canMakePayment().then((result) => {
      console.log("apple pay result", result);
      if (result) {
        setPaymentRequest(paymentRequest);
        handleStripePaymentRequest(paymentRequest);
      } else {
        setFallbackGooglePay(true);
      }
    });
  };

  const handleStripePaymentRequest = (paymentRequest: PaymentRequest) => {
    paymentRequest.on("paymentmethod", async (ev) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      try {
        const name = ev.payerName || "";
        const [firstName, lastName] = name.split(" ");
        const phone = ev.payerPhone || "";
        const email = ev.payerEmail || "";

        const { paymentMethod } = ev;
        const { card, us_bank_account, sepa_debit, au_becs_debit } =
          paymentMethod || {};

        const last4Digit =
          card?.last4 ||
          us_bank_account?.last4 ||
          sepa_debit?.last4 ||
          au_becs_debit?.last4 ||
          "";

        localStorage.removeItem("_dm");
        localStorage.removeItem("_ds");
        localStorage.removeItem("_ecd");

        const data = await createPaymentIntent({
          email,
          phone,
          firstName,
          lastName,
          amount: amountRef.current,
        });

        const { client_secret: clientSecret } = data;

        const { paymentIntent, error: confirmError } =
          await stripe!.confirmCardPayment(
            clientSecret,
            {
              payment_method: ev.paymentMethod.id,
            },
            { handleActions: false },
          );

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete("fail");
          console.log("failed", confirmError);

          confirmError.message && setPaymentError(confirmError.message);
        } else {
          ev.complete("success");

          switch (paymentIntent.status) {
            case "succeeded":
              console.log("Success! Payment received.");

              console.log("user info", {
                email,
                country: countryCode,
                firstName,
                lastName,
                phone,
              });

              await handleCheckout({
                amount: amountRef.current,
                platform: "stripe",
                payment_intent: paymentIntent.id,
                email,
                phone,
                firstName,
                lastName,
                country: countryCode,
                last4Digit,
                urlPage: window.location.href,
                clientIpAddress: await getIPAddress(),
                clientUserAgent: getOSInfo(),
                countryFromIP: await getCountryFromIP(),
                countryCodeFromIP: await getCountryCodeFromIP(),
              });
              break;

            case "processing":
              console.log(
                "Payment processing. We'll update you when payment is received.",
              );
              break;

            case "requires_payment_method":
              // Redirect your user back to your payment page to attempt collecting
              // payment again
              console.log("Payment failed. Please try another payment method.");
              break;

            default:
              console.log("Something went wrong.");
              break;
          }
        }
      } catch (error) {
        console.log("error", error);
        return;
      }
    });
  };

  return (
    <Box mb="0.3rem">
      {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}
    </Box>
  );
};

export default ApplePayButton;
