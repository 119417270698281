import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { CheckoutPayload, Dict } from "../../types/type";
import { getCountryCodeFromIP, getCountryFromIP, getIPAddress, getOSInfo } from "../../utils/utils";

type GooglePayButtonProps = {
  amount: number;
  handleCheckout: (payload: CheckoutPayload) => Promise<void>;
};

const GooglePayButton = ({ amount, handleCheckout }: GooglePayButtonProps) => {
  // Collect.js
  useEffect(() => {
    configNMICollectJS();
  }, [amount]);

  const configNMICollectJS = () => {
    window?.CollectJS?.configure({
      variant: "inline",
      // 1. Callback when processing NMI Card/Google Pay
      callback: async (res: Dict) => {
        const { firstName, lastName, phone, country } =
          res.wallet.billingInfo || {};

        const { email } = res.wallet || {};

        localStorage.removeItem("_dm");
        localStorage.removeItem("_ds");
        localStorage.removeItem("_ecd");

        console.log("user info", {
          email,
          country,
          firstName,
          lastName,
          phone,
        });

        await handleCheckout({
          amount,
          platform: "nmi",
          payment_token: res.token,
          firstName,
          lastName,
          email,
          phone,
          country,
          last4Digit: res.wallet?.cardDetails,
          urlPage: window.location.href,
          clientIpAddress: await getIPAddress(),
          clientUserAgent: getOSInfo(),
          countryFromIP: await getCountryFromIP(),
          countryCodeFromIP: await getCountryCodeFromIP(),
        });
      },
      fields: {
        googlePay: {
          selector: ".googlePayButton",
          emailRequired: true,
          buttonType: "buy",
          buttonColor: "default",
          buttonLocale: "en",
          billingAddressRequired: true,
          billingAddressParameters: {
            phoneNumberRequired: true,
          },
        },
      },
      currency: "USD",
      country: "US",
      price: amount.toString(), // price must provided to Collect.js as a string when using Google Pay.,
    });
  };

  return <Box className="googlePayButton" />;
};

export default GooglePayButton;
