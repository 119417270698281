import { useCheckboxGroup } from "@chakra-ui/react";
import Cookies from "js-cookie";
import qs from "query-string";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Control,
  FieldErrors,
  FormProvider,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useLocation } from "react-router-dom";
import { checkout } from "../apis/payment";
import useMagicPrice from "../components/CountDown/useMagicPrice";
import { COOKIE_KEY, LOCAL_STORAGE_KEY, ROUTES } from "../constants";
import { useCurrentCountryCode } from "../hooks";
import { decodeValue, encodeValue } from "../libs/common";
import { CheckoutInputs, CheckoutPayload } from "../types/type";
import { GTAGEvent, gtag_report_conversion } from "../utils/gtag";
import { getCountryCodeFromIP, getCountryFromIP, getIPAddress, getOSInfo } from "../utils/utils";

type CheckOutContextStateValues = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<CheckoutInputs, any>;
  getValues: UseFormGetValues<CheckoutInputs>;
  errors: FieldErrors<CheckoutInputs>;
  isValid: boolean;
  handleCheckout: (payload: CheckoutPayload) => Promise<void>;
  handleCreditCard: () => void;
  amount: number;
  optionValues: (string | number)[];
  getCheckboxProps: (options: {
    value: string | number;
    index: number;
  }) => Record<string, unknown>;
  isProcessing: boolean;
  paymentError: string;
  setPaymentError: React.Dispatch<React.SetStateAction<string>>;
  toggleCheckBoxValue: () => void;
  clickedToContinue: boolean;
  watch: UseFormWatch<CheckoutInputs>;
  setValue: UseFormSetValue<CheckoutInputs>;
};

const CheckoutContext = createContext<CheckOutContextStateValues | null>(null);

export const CheckoutProvider = ({ children }: PropsWithChildren) => {
  // Prefill the form with query params
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const currentCountryCode = useCurrentCountryCode();

  let country = currentCountryCode;
  const saleUserData = localStorage.getItem(LOCAL_STORAGE_KEY.SALE_USER_DATA);

  if (saleUserData) {
    country = JSON.parse(saleUserData)?.country ?? currentCountryCode;
  }

  const methods = useForm<CheckoutInputs>({
    defaultValues: {
      ...queryParams,
      country,
    },
    mode: "onChange",
  });

  const {
    control,
    getValues,
    formState: { errors, isValid },
    setError,
    clearErrors,
    handleSubmit,
    watch,
    setValue,
  } = methods;

  const salesExpire = Cookies.get(COOKIE_KEY.SALES_EXPIRE)
    ? decodeValue(Cookies.get(COOKIE_KEY.SALES_EXPIRE)!)
    : "";

  const isFinishQuiz = Cookies.get(COOKIE_KEY.IS_FINISH_QUIZ)
    ? decodeValue(Cookies.get(COOKIE_KEY.IS_FINISH_QUIZ)!)
    : "";

  const { base = 27, endless = 47 } = useMagicPrice();

  const checkoutPriceOptions = base
  // salesExpire === "0" && isFinishQuiz === "0" ? 3 : base;

  const {
    value: optionValues,
    getCheckboxProps,
    setValue: setCheckBoxValue,
  } = useCheckboxGroup({
    defaultValue: [checkoutPriceOptions],
  });

  useEffect(() => {
    if (salesExpire === "0" && isFinishQuiz === "0")
      setCheckBoxValue([checkoutPriceOptions]);
    else setCheckBoxValue([base]);
  }, [base]);

  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState<string>("");
  const [clickedToContinue, setClickedToContinue] = useState(false);

  const amount = optionValues.reduce(
    (acc, option) => Number(acc) + Number(option),
    0,
  ) as number;

  const handleCheckout = async (payload: CheckoutPayload) => {
    try {
      setIsProcessing(true);

      // retrieve dial code
      const userData = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEY.SALE_USER_DATA) || "{}",
      );
      const dialCode = userData?.dialCode;

      const [formFirstName, formLastName, formEmail, formPhone, formCountry] =
        getValues([
          "firstName",
          "lastName",
          "email",
          "phone",
          "country",
          "urlPage",
        ]);

      const {
        firstName,
        lastName,
        email,
        phone,
        country,
        amount,
        platform,
        payment_token,
        payment_intent,
        ccnumber,
        ccexp,
        cvv,
        zip,
        last4Digit,
        urlPage,
        clientIpAddress,
        clientUserAgent,
        countryFromIP,
        countryCodeFromIP,
      } = payload;

      // Get customer info from form first, if it doesn't have then get from Digital Wallets
      const checkoutPayload: CheckoutPayload = {
        firstName: formFirstName || firstName,
        lastName: formLastName || lastName,
        email: formEmail || email,
        phone: "+" + dialCode + (formPhone || phone),
        country: formCountry || country,
        amount,
        platform,
        zip,
        last4Digit,
        urlPage,
        clientIpAddress,
        clientUserAgent,
        countryFromIP,
        countryCodeFromIP,
      };

      // Add payment details to payload based on payment method
      if (payment_token) {
        checkoutPayload.payment_token = payment_token;
      } else if (ccnumber && ccexp && cvv) {
        checkoutPayload.ccnumber = ccnumber;
        checkoutPayload.ccexp = ccexp;
        checkoutPayload.cvv = cvv;
      } else if (payment_intent) {
        checkoutPayload.payment_intent = payment_intent;
      }

      const { data } = await checkout(checkoutPayload);

      console.log("result", data.metadata.verify);

      // If success redirect to the verify page for auto login
      if (data?.message === "success") {
        Cookies.set(COOKIE_KEY.SALES_EXPIRE, encodeValue("1"), {
          expires: 365 * 10,
          secure: true,
          sameSite: "Strict",
        });

        Cookies.set(COOKIE_KEY.IS_FINISH_QUIZ, encodeValue("1"), {
          expires: 365 * 10,
          secure: true,
          sameSite: "Strict",
        });

        Cookies.remove(COOKIE_KEY.START_TIME);

        gtag_report_conversion(GTAGEvent.FRONT_END_PURCHASE, {
          url: `${data.metadata.verify + "&to=" + ROUTES.UPSELL1}`,
          value: checkoutPayload.amount,
          transaction_id:
            data.metadata.user.paymentIntent ??
            data.metadata.user.customerVaultId,
        });
      } else if (data?.metadata?.cardInfor) {
        console.log("cardInfor", data.metadata.cardInfor.responsetext);

        setPaymentError(data.metadata.cardInfor.responsetext);
      }
    } catch (error) {
      console.log("Error checkout", error);
      // Show payment Error due to Axios Request Failed
      setPaymentError("Something went wrong");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCreditCard = handleSubmit(async () => {
    setClickedToContinue(true);
    clearErrors(["ccnumber", "zip"]);

    const [
      firstName,
      lastName,
      email,
      phone,
      ccnumber,
      ccexp,
      cvv,
      country,
      zip,
    ] = getValues([
      "firstName",
      "lastName",
      "email",
      "phone",
      "ccnumber",
      "ccexp",
      "cvv",
      "country",
      "zip",
    ]);

    if (!ccnumber || !ccexp || !cvv) {
      setError("ccnumber", {
        type: "manual",
        message: "The card info is required",
      });

      return;
    }

    if (country === "US" && !zip) {
      setError("zip", {
        type: "manual",
        message: "The zip code is required",
      });

      return;
    }

    if (!isValid) {
      handleSubmit(() => { });
      return;
    }

    const payload: CheckoutPayload = {
      amount,
      platform: "nmi",
      ccnumber: ccnumber.replace(/\s/g, ""),
      ccexp: ccexp.replace(/ /g, ""),
      cvv,
      firstName,
      lastName,
      email,
      phone,
      last4Digit: ccnumber.slice(-4),
      urlPage: window.location.href,
      clientIpAddress: await getIPAddress(),
      clientUserAgent: getOSInfo(),
      zip,
      countryFromIP: await getCountryFromIP(),
      countryCodeFromIP: await getCountryCodeFromIP(),
    };

    if (country) {
      payload.country = country;
    }

    if (zip && country === "US") {
      payload.zip = zip;
    }

    localStorage.removeItem("_dm");
    localStorage.removeItem("_ds");
    localStorage.removeItem("_ecd");

    await handleCheckout(payload);
  });

  const toggleCheckBoxValue = () => {
    if ([3, base].includes(amount)) {
      setCheckBoxValue([checkoutPriceOptions, endless]);
    } else {
      setCheckBoxValue([checkoutPriceOptions]);
    }
  };

  const providerValue = {
    control,
    getValues,
    errors,
    isValid,
    handleCheckout,
    handleCreditCard,
    amount,
    optionValues,
    getCheckboxProps,
    isProcessing,
    paymentError,
    setPaymentError,
    toggleCheckBoxValue,
    clickedToContinue,
    watch,
    setValue,
  };

  return (
    <CheckoutContext.Provider value={providerValue}>
      <FormProvider {...methods}>
        {children}
        <AutoFocusingErrorInputs />
      </FormProvider>
    </CheckoutContext.Provider>
  );
};

const AutoFocusingErrorInputs = () => {
  const {
    formState: { errors },
  } = useFormContext<CheckoutInputs>();

  useEffect(() => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const firstErrorKey = errorKeys[0];

      const firstErrorInput = document.querySelector<HTMLInputElement>(
        `input[name=${firstErrorKey}]`,
      );

      firstErrorInput?.focus();
    }
  }, [errors]);

  return null;
};

export const useCheckout = (): CheckOutContextStateValues => {
  const context = useContext(CheckoutContext);

  if (!context) {
    throw new Error("useCheckout must be used within a CheckoutProvider");
  }
  return context;
};
