import { Box, Image } from "@chakra-ui/react";
import { ComponentPropsWithoutRef, ReactNode, useEffect } from "react";
import SalesArrow from "../../assets/images/arrow.svg";
import { avatars } from "../../constants";
import { useIntersectionElement } from "../../hooks/useIntersectionElement";
import { cn } from "../../utils/utils";
import SalesButton from "../SalesButton";
import GetInstantParticipated from "./parts/GetInstantParticipated";
import GetInstantServices from "./parts/GetInstantServices";
import "./styles.css";
import { useIsMobile } from "../../hooks/useIsMobile";

export interface GetInstantAccessProps
  extends ComponentPropsWithoutRef<"section"> {
  title: string;
  isFillQuiz?: boolean;
  videoPlayer: ReactNode;
  isDelayedContent: boolean;
  isMobile: boolean;
}

const kDelayedContentStyle = (isDelayedContent: boolean, isMobile: boolean) =>
  cn(
    isDelayedContent
      ? `${isMobile ? "visible" : "invisible"} opacity-0`
      : "opacity-100 transition-all ease-in-out duration-1000",
  );

export const getInstantAccessIntersectingEvent = "getInstantAccessIntersecting";

const GetInstantAccess = ({
  title,
  isFillQuiz,
  videoPlayer,
  isDelayedContent,
  className,
  ...props
}: GetInstantAccessProps) => {
  const [ref, entry] = useIntersectionElement();
  const isMobile = useIsMobile();

  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent("getInstantAccessIntersecting", {
        detail: entry?.isIntersecting,
      }),
    );
  }, [entry?.isIntersecting]);

  return (
    <section
      {...props}
      className={cn(
        `${isFillQuiz ? "h-full xs:h-full 5xl:h-full" : ""}`,
        "relative flex flex-col items-center justify-center pb-[49px] pt-8 text-center md:pb-[60px]",
        className,
      )}
    >
      <h1
        className={`${isFillQuiz ? "mb-6 mt-25 text-[30px] capitalize fold:text-[26px] xxs:mb-6 xxs:text-[28px] xs:mb-6 xs:w-[380px] ltxs:w-full ltxs:text-[31px] ltsm:w-full ltsm:text-[32px] sm:mb-10 sm:w-full sm:max-w-[400px] md:mb-2 md:max-w-[650px] md:text-32 lg:mb-6 lg:w-full lg:max-w-[750px] lg:text-[38px] xl:mb-0 xl:max-w-[700px] xl:text-[38px] xl:leading-[45px] 5xl:mb-6 5xl:max-w-[850px] 5xl:text-[44px] 6xl:max-w-[800px] 6xl:text-[42px] 6xl:leading-[60px]" : "mb-[9px] xs:w-[380px] md:mb-[18px] md:text-[36px] md:leading-10 lg:text-44 xl:text-48"} md:w-5xl relative mx-auto px-8 text-[28px] font-[900] leading-[37px] text-black sm:w-[380px] md:w-full md:px-16 lg:max-w-6xl`}
      >
        {title}
        {isFillQuiz && (
          <Image
            src={SalesArrow}
            alt="Sale arrow action"
            loading="lazy"
            className={cn(
              "absolute -bottom-[45px] fold:right-[30px] sm:right-[10px] md:-bottom-[50px] md:right-[40px] lg:-bottom-[50px] lg:right-[30px] lg:rotate-6 xl:-bottom-[60px] xl:right-[20px] 2xl:-bottom-[50px] 2xl:right-[10px] 3xl:-bottom-[50px] 3xl:right-[15px] 5xl:-bottom-[60px] 5xl:right-[10px] 6xl:right-[30px]",
              "h-[45px] w-[25px] md:h-[50px] md:w-[45px] lg:h-[80px]",
              "origin-top xxs:right-[20px] xs:right-[30px] md:rotate-6",
            )}
          />
        )}
      </h1>
      {!isFillQuiz && (
        <p className="relative mx-auto mb-[25px] w-[350px] px-8 text-center text-[17px] font-[500] xxxs:w-[300px] md:mb-8.75 md:w-full md:max-w-5xl md:px-16 lg:mb-11 lg:max-w-6xl lg:text-24 xl:mb-[67px]">
          All without ever showing your face or creating the videos yourself
        </p>
      )}
      <Box position="relative" w="full" mb="10">
        {videoPlayer}
      </Box>
      <div className={kDelayedContentStyle(isDelayedContent, isMobile)}>
        <SalesButton ref={ref} className="mb-[13px] md:mb-[21px] md:mt-9" />

        <GetInstantParticipated
          items={avatars}
          className="mb-[28px] justify-center md:mb-10"
        />

        <GetInstantServices />
      </div>

      {/* Background gradient */}
      <div className="red-radial-gradient absolute bottom-0 left-0 -z-10 h-full w-full"></div>
    </section>
  );
};

export default GetInstantAccess;
