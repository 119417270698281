/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { RedButton } from "../ui";
import { upsell } from "../../apis/payment";
import { ArrowBack } from "../../assets/icons/ArrowBack";
import success from "../../assets/images/success.webp";
import { useAuth } from "../../providers/AuthProvider";
import useMagicPrice from "../CountDown/useMagicPrice";
import { getCountryCodeFromIP, getCountryFromIP, getIPAddress } from "../../utils/utils";

type PurchaseEndlessVideoIdeaModalProps = {
  handlePaymentFailed: () => void;
} & PropsWithChildren;


const SuccessPayment = () => {
  return (
    <ModalContent
      maxW={{
        base: "calc(100% - 48px)",
        md: "477px",
      }}
      maxH="318px"
      p={{
        base: "40px 24px",
        md: "55px 85px",
      }}
      borderRadius="20px"
    >
      <ModalBody p={0}>
        <div className="mx-auto flex h-[60px] w-[60px] items-center justify-center rounded-full bg-[#F7F7F7]">
          <img
            src={success}
            alt="success payment"
            className="h-[32px] w-[32px]"
          />
        </div>
        <h3 className="mx-auto mb-[16px] mt-[20px] max-w-[297px] text-center text-26 font-black">
          Congrats! Your upgrade was processed
        </h3>

        <p className="mx-auto max-w-[307px] px-[15px] text-center text-18 font-[450] md:px-0">
          You have now unlocked the Endless Video Idea System!
        </p>
      </ModalBody>
    </ModalContent>
  );
};

const PaymentInProgress = memo(
  ({
    handleUnlockAccess,
    isProcessing,
  }: {
    handleUnlockAccess: () => void;
    isProcessing: boolean;
  }) => {
    const { endless: amount } = useMagicPrice();

    return (
      <ModalContent
        maxW={{
          base: "calc(100% - 48px)",
          md: "614px",
        }}
        p={{
          base: "30px 19px",
          md: "30px",
        }}
        borderRadius="20px"
      >
        <ModalBody p={0}>
          <h3 className="mb-[25px] text-center text-26 font-black">
            The Endless Video Idea System
          </h3>

          <p className="mx-auto max-w-[508px] text-center text-18 font-[450]">
            Save yourself time and generate unique video ideas for your channel
            in just 10 minutes. To keep earning, you need to stay creative and
            keep posting new videos. Jake's system automates the brainstorming
            process with a proven formula.
          </p>

          <div className="mt-7.5 px-[5px] md:px-0">
            <RedButton
              className="h-[43px] !justify-center rounded-[15px] md:rounded-[8px]"
              onClick={handleUnlockAccess}
              disabled={isProcessing}
            >
              <ArrowBack />
              <p className="text-16px text-center font-bold md:text-18 md:uppercase">
                Unlock access for just ${amount}
              </p>
            </RedButton>

            <p className="mt-[14px] text-center text-16 font-[450] text-[#6C6C6C]">
              Your card on file will be billed ${amount}
            </p>
          </div>
        </ModalBody>
      </ModalContent>
    );
  },
);

const PurchaseEndlessVideoIdeaModal = ({
  handlePaymentFailed,
  children,
}: PurchaseEndlessVideoIdeaModalProps) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [successPayment, setSuccessPayment] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { checkAuth } = useAuth();
  const { endless: amount } = useMagicPrice();


  useEffect(() => {
    window.addEventListener("viral-open-success-payment", () => {
      setSuccessPayment(true);
      onOpen();
    });
  }, []);

  const handleUnlockAccess = useCallback(async () => {
    try {
      setIsProcessing(true);
      await upsell({
        amount,
        clientIpAddress: await getIPAddress(),
        countryFromIP: await getCountryFromIP(),
        countryCodeFromIP: await getCountryCodeFromIP(),
      });
      setSuccessPayment(true);
      checkAuth();
    } catch (error) {
      handlePaymentFailed();
      onClose();
    } finally {
      setIsProcessing(false);
    }
  }, [handlePaymentFailed, onClose]);

  return (
    <>
      <div onClick={onOpen}>{children}</div>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          if (successPayment) setSuccessPayment(false);
          onClose();
        }}
        isCentered
      >
        <ModalOverlay />
        {successPayment ? (
          <SuccessPayment />
        ) : (
          <PaymentInProgress
            isProcessing={isProcessing}
            handleUnlockAccess={handleUnlockAccess}
          />
        )}
      </Modal>
    </>
  );
};

export default PurchaseEndlessVideoIdeaModal;
