import { useDisclosure } from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { upsell } from "../../../apis/payment";
import { DoubleArrowRight } from "../../../assets/icons";
import { ROUTES, URL_PARAMS } from "../../../constants";
import { useDelayNavigation } from "../../../hooks/useDelayNavigation";
import { gtag_report_conversion, GTAGEvent } from "../../../utils/gtag";
import { cn, getCountryCodeFromIP, getCountryFromIP, getIPAddress } from "../../../utils/utils";
import AbstractButton from "../../Button";
import CheckoutModal from "../../CheckoutModal";

const ListStyleItem = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("mt-2.5 flex flex-row gap-[10px] md:gap-2", className)}>
      <div className="mt-[2px] h-4 w-4 rounded-full border-[2.665px] border-[#F9A7A0] bg-[#D92D20] md:h-5 md:w-5 md:border-[4px]"></div>
      <div className="flex-1">{children}</div>
    </div>
  );
};

export const Offers = () => {
  const [isUpselling, setIsUpselling] = useState(false);
  const navigate = useDelayNavigation();
  const [upsellAmount, setUpsellAmount] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleNavigateToDownSellPage = () => {
    navigate(`${ROUTES.DOWNSELL}?h=${URL_PARAMS.H_PARAM}`);
  };

  const handleClickUpsellPayment = async (amount: number) => {
    try {
      setIsUpselling(true);
      const res = await upsell({
        amount,
        clientIpAddress: await getIPAddress(),
        countryFromIP: await getCountryFromIP(),
        countryCodeFromIP: await getCountryCodeFromIP(),
      });

      gtag_report_conversion(GTAGEvent.UPSELL_PURCHASE, {
        url: ROUTES.OTOSU,
        value: amount,
        transaction_id:
          res.data.metadata.customerVaultId ?? res.data.metadata.paymentIntent,
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log("error", error);
      setUpsellAmount(amount);
      onOpen();
    } finally {
      setIsUpselling(false);
    }
  };

  return (
    <div className="mt-12.5 px-6 pb-15 md:mt-15 md:pb-[80px] xl:px-0">
      <div className="mx-auto w-fit rounded-[42px] border border-[#E1E1E1] bg-[#FFF8F8] px-[22px] py-[12px] text-14 font-semibold uppercase md:px-[42px] md:py-[18px] md:text-18">
        NEW MEMBER ONE TIME OFFER
      </div>
      <h2 className="mt-[22px] text-center text-26 font-extrabold uppercase md:text-44">
        CHOOSE YOUR UPGRADE:
      </h2>
      <div className="mx-auto mt-7.5 flex max-w-[954px] flex-col gap-[26px] md:mt-15 md:flex-row">
        <div className="h-fit w-full overflow-hidden rounded-[15px] border border-[#E7E7E7] bg-[#F9FAFB] px-5 py-10 md:px-[30px]">
          <div className="text-center text-22 font-bold md:text-26 md:font-black">
            GET VIEWS ON DEMAND
          </div>
          <img
            src="/images/upsell1/get-views-on-demand.webp"
            alt="get-views-on-demand.webp"
            className="mx-auto mt-[30px] h-[165px] w-[305px] md:h-[213px] md:w-[404px]"
          />
          <div className="mt-[20px] flex flex-col justify-between gap-5 text-center md:flex-row md:gap-0 md:text-left">
            <div>
              <p className="text-24 font-black text-[#D92D20] md:text-26">
                ONLY $197
              </p>
              <p className="mt-[6px] text-16 font-light md:mt-1">
                (One-time payment)
              </p>
            </div>
            <div className="flex items-center justify-end">
              <p className="py-auto h-fit w-full rounded-[42px] border border-[#D92D20] bg-[#FFF8F8] px-[19px] py-[9px] text-center text-16 font-bold md:w-fit md:py-[8px]">
                $297 After Sale
              </p>
            </div>
          </div>
          <div className="before:content relative my-[18px] h-[1px] w-full bg-[#E7E7E7] before:absolute before:ml-[30px] before:hidden before:h-[1px] before:w-full before:bg-[#E7E7E7] md:my-[15px] before:md:block"></div>
          <div className="font-450 text-18 md:text-16">
            <p className="text-20 font-black">Best choice if you</p>
            <ListStyleItem className="mt-[15px] md:mt-[17px]">
              Are serious about controlling your financial future
            </ListStyleItem>

            <ListStyleItem>
              Want to get your first paycheck from YouTube as soon as possible
            </ListStyleItem>

            <ListStyleItem>
              Want to predictably earn money from YouTube long-term
            </ListStyleItem>
          </div>
          <AbstractButton
            className="mt-[30px] w-full gap-2 md:mt-10 md:h-[53px] md:gap-[10px]"
            disabled={isUpselling}
            onClick={() => handleClickUpsellPayment(197)}
          >
            <span className="text-16 capitalize md:text-20 md:uppercase">
              Upgrade Now
            </span>
            <DoubleArrowRight />
          </AbstractButton>
          <p className="mt-[14px] text-center text-16 text-[#959595] md:mt-[10px]">
            Your card on file will be billed $197
          </p>
        </div>

        <div className="relative mt-[28px] w-full rounded-[15px] border border-[#D92D20] bg-[#FEF7F8] px-[19px] pb-10 md:mt-0 md:px-[30px]">
          <div className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="w-max rounded-[17px] border border-[#D92D20] bg-[#FFF8F8] px-[22px] py-3 text-center text-12 font-semibold md:w-fit md:whitespace-nowrap md:rounded-[42px] md:bg-white md:px-[35px] md:py-[15px] md:text-14">
              97% OF OUR STUDENTS CHOOSE THIS UPGRADE
            </div>
          </div>

          <div className="mx-auto mt-10 max-w-[526px] text-center text-22 font-extrabold uppercase md:text-26">
            GET VIEWS ON DEMAND AND ADD MORE INCOME STREAMS
          </div>

          <img
            src="/images/upsell1/get-views-on-demand-and-income.webp"
            alt="get-views-on-demand-and-income"
            className="mx-auto mt-[20px] h-[165px] w-[305px] md:h-[213px] md:w-[404px]"
          />

          <div className="mt-[20px] flex flex-col justify-between md:flex-row">
            <div className="text-center md:text-left">
              <p className="text-24 font-black text-[#D92D20] md:text-26">
                ONLY $297
              </p>
              <p className="mt-[6px] text-16 font-light md:mt-1">
                (One-time payment)
              </p>
            </div>
            <div className="mt-5 flex items-center justify-end md:mt-0">
              <p className="h-fit w-full rounded-[42px] border border-[#D92D20] bg-[#FFF8F8] px-[19px] py-[9px] text-center text-18 font-bold md:w-fit md:py-2 md:text-16">
                $590 After Sale
              </p>
            </div>
          </div>
          <div className="before:content relative my-[18px] h-[1px] w-full bg-[#E7E7E7] before:absolute before:ml-[30px] before:hidden before:h-[1px] before:w-full before:bg-[#E7E7E7] md:my-[15px] before:md:block"></div>
          <div className="text-18 font-normal md:text-16">
            <p className="text-20 font-black">Best choice if you</p>
            <ListStyleItem className="mt-[17px] md:mt-[15px]">
              Are serious about making your income secure, reliable &
              recession-proof
            </ListStyleItem>

            <ListStyleItem>
              Want to have more than 1 full-time income stream
            </ListStyleItem>

            <ListStyleItem>
              Want to get paid even before posting videos
            </ListStyleItem>
            <ListStyleItem>
              And everything that's included in GET VIEWS ON DEMAND option!
            </ListStyleItem>
            <ListStyleItem>Plus a secret bonus</ListStyleItem>
          </div>

          <AbstractButton
            className="mt-[30px] w-full gap-[10px] md:mt-[25px] md:h-[53px]"
            disabled={isUpselling}
            onClick={() => handleClickUpsellPayment(297)}
          >
            <span className="text-16 capitalize md:text-20 md:uppercase">
              Upgrade Now
            </span>
            <DoubleArrowRight />
          </AbstractButton>
          <p className="mt-5 text-center text-16 text-[#959595] md:mt-[10px]">
            Your card on file will be billed $297
          </p>
        </div>
      </div>

      <div
        className="mx-auto mt-12.5 max-w-[980px] text-center text-18 font-semibold text-[#D92D20] md:mt-15 md:text-24"
        onClick={handleNavigateToDownSellPage}
      >
        <span className="cursor-pointer border-b-[2px] border-[#D92D20]">
          No, thanks I don't want the '7-Figure Launchpad'. I don't want
          everything figured out for me, and I’d rather implement everything on
          my own
        </span>
      </div>

      <CheckoutModal amount={upsellAmount} isOpen={isOpen} onClose={onClose} />
    </div>
  );
};
